import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import RenderPortfolioItems from '../components/utils/portfolio-items'


function PortfolioPage() {

    const [activeFilter, activeFilterApplied] = useState('');

    const [resultsFound, setResultsNumber] = useState(0);

    let resetFiltersBtn = <span className={activeFilter === '' ? '' : 'portfolio-category-filter__results--clear-results'} onClick={() => activeFilterApplied('')}>Clear Filters?</span>;

    function handleChange(newValue) {
        setResultsNumber(newValue)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    })
    
    return (
    <Layout>
        <SEO title="Portfolio" />
        
        <div className="row main-content container" id="mainContent">
            
            <div className="row main-content__box">
            <h2>Portfolio</h2>
            <div className="row">
            
            <div className="portfolio-category-filter">
            <h6>Filter by category:</h6>
            <div className="portfolio-category-filter__filters">

                <span className={activeFilter === "cms" ? "portfolio-category-filter__filters__pills portfolio-category-filter__filters__pills--active" : "portfolio-category-filter__filters__pills"} onClick={() => activeFilterApplied('cms')}>CMS</span>

                <span className={activeFilter === "react" ? "portfolio-category-filter__filters__pills portfolio-category-filter__filters__pills--active" : "portfolio-category-filter__filters__pills"} onClick={() => activeFilterApplied('react')}>ReactJS</span>

                <span className={activeFilter === "vueJS" ? "portfolio-category-filter__filters__pills portfolio-category-filter__filters__pills--active" : "portfolio-category-filter__filters__pills"} onClick={() => activeFilterApplied('vueJS')}>VueJS</span>

            {activeFilter !== '' ? <div className="portfolio-category-filter__results"> <span>{resultsFound} results found</span> {resetFiltersBtn}</div> : ''}

            </div>

            </div>
            </div>

            <section className="main-content portfolio">
            
            <RenderPortfolioItems activeFilter={activeFilter} onChange={handleChange} />
            
            </section>
            
            </div> 
            </div>

        </Layout>
        )
    
}

export default PortfolioPage