import React, { useEffect } from "react";
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

function RenderPortfolioItems(props) {
    let activeFilter = props.activeFilter,
        resultsFound = 0;

    function handleChange(newNum) {
        props.onChange(newNum);
    }

    useEffect(() => {
        handleChange(resultsFound);
    })

    return (
    <StaticQuery query={portfolioQuery} render={data =>  {
        let portfolioItems = data.allMarkdownRemark.edges;
            
        let filteredPortfolioItems = portfolioItems.filter(({ node }) => (
            activeFilter !== '' ? node.frontmatter.tags.indexOf(activeFilter) !== -1 : node
        ));

        resultsFound = filteredPortfolioItems.length;

        return (
            filteredPortfolioItems.map(({ node }) => (
            <Link key={node.id} to={node.frontmatter.path}>
            <div className={node.frontmatter.live === 'Yes' ? "card row" : "card card--coming-soon row"} key={node.id} data-tag={node.frontmatter.tags}>
            <div className="card__image__wrapper columns six">
            <img className="card__image" src={node.frontmatter.image} title={node.frontmatter.title} alt={node.frontmatter.title} />
            </div>
            
            <div className="card__content_wrapper columns six">
            <div className="card__content">
            <h3>{node.frontmatter.title}</h3>
            <p>{node.frontmatter.shortDescription}</p>
            </div>
            <button className="card__button">
            See project
            </button>
            </div>
            </div>
            </Link>
            ))
        )
        }} 
    />
    )

}

export const portfolioQuery = graphql`
query {
    allMarkdownRemark(filter: {collection: {eq: "portfolio"}}, sort: {fields: frontmatter___date, order: DESC}) {
        edges {
            node {
                id
                frontmatter {
                    title
                    image
                    tags
                    path
                    date
                    live
                    shortDescription
                }
            }
        }
    }
}`;

export default RenderPortfolioItems;